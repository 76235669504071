/* eslint-disable linebreak-style */
import { all } from 'redux-saga/effects';
import auth from './auth'
import licenseSagas from '../redux/LicenseManager/saga'
import calibrationSagas from '../redux/calibration/saga'
import SparePartsSagas from '../redux/Spareparts/saga';
import RecordListSagas from '../redux/Trackrecord/saga';
import PricingListSagas from '../redux/Pricing/saga';
import projectMarginSagas from '../redux/projectMargin/saga';
import assetUtilizationSagas from '../redux/AssetUtilization/saga';
import financeMappingSagas from '../redux/FinanceMapping/saga';

export default function* rootSaga() {
  yield all([
    auth(),
    licenseSagas(),
    calibrationSagas(),
    SparePartsSagas(),
    RecordListSagas(),
    PricingListSagas(),
    projectMarginSagas(),
    assetUtilizationSagas(),
    financeMappingSagas()
  ]);
}
