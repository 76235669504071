/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined, RightCircleTwoTone, FilePdfOutlined, DownCircleTwoTone, RightCircleOutlined } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography, Popover,
    Upload, notification, DatePicker, Drawer, Select, Layout, Row, Col, Card
} from 'antd';
// import { IonIcon } from "@ionic/react";
// import { closeOutline, cameraOutline, diamondOutline, chatbubbleOutline, alarmOutline, ellipsisHorizontal, ellipsisVertical, eyeOutline, createOutline, downloadOutline } from "ionicons/icons";
import { get } from 'lodash';
import moment from 'moment';

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history';
import MasteForm from './MasteForm';
import MasteForm_Mappings from './Mappings';
import Supplement from './Supplement';
import MonthlyEndsResults from './MonthlyEndsResults';
import CommonAGGridTable from './CommonAGGridTable';


const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const RawDataPage = (props) => {

    const {
        isVisible, setIsVisible
    } = props;

    const [isAccountReceivableVisible, setIsAccountReceivableVisible] = useState(false);
    const [isConsolidateVisible, setIsConsolidateVisible] = useState(false);
    const [isProjectMarginVisible, SetIsProjectMarginVisible] = useState(false);
    const [isProjectWIBRefurbVisible, setIsProjectWIBRefurbVisible] = useState(false);

    const [userName, setUserName] = useState('');
    const [userGroup, setUserGroup] = useState([]);

    const [isRawData, setIsRawData] = useState(false);

    const projectMargin = {
        CRC_PM_FBL3N: 'CRC_PM_FBL3N',
        CM_CRC_PM_FBL3N: 'CM_CRC_PM_FBL3N',
        CRC_PM_FBL3N_CONSOL: 'CRC_PM_FBL3N_CONSOL'
    }

    const accountReceivable = {
        ACCOUNTS_RECEIVABLE_FBL3N_DAILY: 'FBL3N_Daily',
        ACCOUNTS_RECEIVABLE_FBL3N_ME: 'FBL3N_ME',
        ACCOUNTS_RECEIVABLE_FBL3N_CONSOL: 'FBL3N_CONSOL',
        ACCOUNTS_RECEIVABLE_SUBLEDGER_FBL5N_DAILY: 'Subledger_FBL5N_Dialy',
        ACCOUNTS_RECEIVABLE_SUBLEDGER_FBL5N_ME: 'Subledger_FBL5N_ME',
        ACCOUNTS_RECEIVABLE_FBL3N_CONSOL: 'Subledger_FBL5N_CONSOL'
    }
    const consolidatedPL = {
        CM_SOG_PL_GL_ITEMS_FBL3N: 'CM SOG PL GL ITEMS FBL3N',
        CM_SOG_PL_LABOR_SETTLEMENT_KE5Z: 'CM_SOG PL Labor Settlement KE5Z',
        SOG_PL_GL_ITEMS_FBL3N: 'SOG PL GL ITEMS_FBL3N',
        SOG_PL_GL_ITEMS_FBL3N_2023: 'SOG PL GL ITEMS_FBL3N_2023',
        SOG_PL_LABOR_SETTLEMENT_KE5Z: 'SOG PL Labor Settlement_KE5Z',
        SOG_PL_LABOR_SETTLEMENT_KE5Z_2023: 'SOG PL Labor Settlement_KE5Z_2023',
        SOG_PL_GL_ITEMS_CONSOL: 'SOG PL GL ITEMS_CONSOL',

    }

    const projectWIPRefurbCost = {

        PROJECT_WIP_REFURB_COST_FBL3N_DAILY: 'FBL3N_Daily',
        PROJECT_WIP_REFURB_COST_FBL3N_ME: 'FBL3N_ME',
        Project_WIP_Refurb_Cost_FBL3N_CONSOL: 'FBL3N_CONSOL',
    }

    return (

        <div className="d-flex flex-column h-100">
            {!isAccountReceivableVisible && !isConsolidateVisible && !isProjectWIBRefurbVisible && !isProjectMarginVisible && <div>
                <div className="flex-auto">
                    <div className="d-flex mb-2 align-items-center tab-content-title">
                        <div className='back_equip' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined onClick={(e) => {
                                    setIsVisible(false)
                                    history.push('/FinanceMapping');
                                }} />
                            </Tooltip>
                        </div>
                        <Title className="mb-0 mt-1 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                            Raw Data
                        </Title>

                    </div>

                </div>
                <div className="custom-line" />

                <div >
                    <Row gutter={24} className="detail-rows">
                        <Col span={40}>
                            <Card className='custom-card'
                                onClick={() => {
                                    setIsAccountReceivableVisible(true)
                                }}
                                style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                <div style={{ marginTop: '-10px' }} >
                                    <div >
                                        {"Accounts Receicvable"}
                                    </div>
                                    <div style={{ paddingTop: '5px' }}>
                                        <RightCircleOutlined></RightCircleOutlined>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col span={40}>
                            <Card className='custom-card'
                                onClick={() => {
                                    setIsConsolidateVisible(true)
                                }}
                                style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                <div style={{ marginTop: '-10px' }} >
                                    <div >
                                        {"Consolidated P&L"}
                                    </div>
                                    <div style={{ paddingTop: '5px' }}>
                                        <RightCircleOutlined></RightCircleOutlined>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col span={40}>
                            <Card className='custom-card'
                                onClick={() => {
                                    SetIsProjectMarginVisible(true)
                                    history.push('/Project-margin');
                                }}
                                style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                <div style={{ marginTop: '-10px' }} >
                                    <div >
                                        {"Project Margin"}
                                    </div>
                                    <div style={{ paddingTop: '5px' }}>
                                        <RightCircleOutlined></RightCircleOutlined>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col span={40}>
                            <Card className='custom-card'
                                onClick={() => {
                                    setIsProjectWIBRefurbVisible(true)
                                }}
                                style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                                <div style={{ marginTop: '-10px' }} >
                                    <div >
                                        {"Project WIP_Refurb Cost"}
                                    </div>
                                    <div style={{ paddingTop: '5px' }}>
                                        <RightCircleOutlined></RightCircleOutlined>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>


            </div>
            }



            {isAccountReceivableVisible &&
                <CommonAGGridTable
                    isVisible={isAccountReceivableVisible}
                    setIsVisible={setIsAccountReceivableVisible}
                    tableNames={accountReceivable}
                    tittle='Accounts Receivable'
                >

                </CommonAGGridTable>

            }

            {isConsolidateVisible &&
                <CommonAGGridTable
                    isVisible={isConsolidateVisible}
                    setIsVisible={setIsConsolidateVisible}
                    tableNames={consolidatedPL}
                    tittle='Consolidated P&L'
                >

                </CommonAGGridTable>

            }

            {/* {isProjectMarginVisible &&
                // <CommonAGGridTable
                //     isVisible={isProjectMarginVisible}
                //     setIsVisible={SetIsProjectMarginVisible}
                //     tableNames={projectMargin}
                //     tittle='Project Margin'
                // >

                // </CommonAGGridTable>

              

            } */}



            {isProjectWIBRefurbVisible &&
                <CommonAGGridTable
                    isVisible={isProjectWIBRefurbVisible}
                    setIsVisible={setIsProjectWIBRefurbVisible}
                    tableNames={projectWIPRefurbCost}
                    tittle='Project WIP_Refurb Cost'
                >

                </CommonAGGridTable>

            }

        </div>
    );
};
const mapStateToProps = ({ }) => {
    return {
    }
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(RawDataPage);